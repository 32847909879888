import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const UploadOmniXls = ({
  handleFileChange,
  fileUploadStatus,
  setFileTitle,
  pageTitle,
  firstTabValue,
  isFileAvailable,
  counter,
  setInputTargetFiles,
  isTargetAvilable,
}) => {
  // const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(false);

  const handleFileUpload = (e) => {

    if (!e.target.files.length) {
      setError("No file selected.");
      fileUploadStatus(false);
      return;
    }

    const file = e.target.files[0];

    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      setError("Please upload a valid Excel file.");
      return;
    }
    const reader = new FileReader();
    let filePath = e.target.value;
    let fileName = filePath.split("\\").pop();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      if (firstSheetName === firstTabValue) {
        setError(""); // Clear any previous error
        setMsg(fileName + " File uploaded");
        setDisplayMsg(true);
        fileUploadStatus(true);
        if (isFileAvailable[counter] !== undefined) {
          isFileAvailable[counter] = filePath;
          isTargetAvilable[counter] = file;
        } else {
          handleFileChange(filePath);
          setInputTargetFiles(file);
        }

        // handleFileChange(filePath);
        fileUploadStatus(true);
      } else {
        setDisplayMsg(true);
        setError(`"The first tab is not named '${firstTabValue}'."`);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  useEffect(
    function () {
      setFileTitle(pageTitle);
      fileUploadStatus(false);
      if (isFileAvailable[counter] !== undefined) {
        setMsg(isFileAvailable[counter].split("\\").pop() + " file uploaded");
        setDisplayMsg(true);
        fileUploadStatus(true);
      }
    },
    [msg, isFileAvailable, counter, setFileTitle, pageTitle, fileUploadStatus]
  );
  return (
    <>
      <div className="fileLabel">
        <div className="fileLabel">
          <input
            type="file"
            id="uploadOmniXls"
            accept=".xls,.xlsx"
            className="uploadInput"
            onChange={handleFileUpload}
          />
          <label htmlFor="uploadOmniXls" className="icon xls-icon"></label>
          {displayMsg && (
            <p className={`statusMsg ${error ? "errorMsg" : "succesMsg"}`}>{`${
              error ? error : msg
            }`}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadOmniXls;
