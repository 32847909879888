import React, { useEffect, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";

// Set the workerSrc for pdfjsLib
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const UploadPdf = ({
  handleFileChange,
  fileUploadStatus,
  setFileTitle,
  counter,
  isFileAvailable,
  setInputTargetFiles,
  isTargetAvilable,
}) => {
  const [error, setError] = useState("");
  const [msg, setMsg] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(false);

  let fileName;

  const handleFileUpload = (e) => {
    if (!e.target.files.length) {
      setError("No file selected.");
      fileUploadStatus(false);
      return;
    }
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      setError("Please upload a valid PDF file.");
      return;
    }
    const reader = new FileReader();
    let filePath = e.target.value;
    fileName = filePath.split("\\").pop();

    reader.onload = async (event) => {
      const fileContent = event.target.result;
      try {
        const pdf = await pdfjsLib.getDocument({ data: fileContent }).promise;
        let text = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(" ");
          text += `${pageText} `;
        }
        // Check if the word "sparks" is within 100 words context
        const words = text.split(/\s+/);
        const sparksIndex = words.indexOf("sparks:");
        if (sparksIndex !== -1) {
          setError(""); // Clear any previous error
          setMsg(fileName + " File uploaded");
          setDisplayMsg(true);
          fileUploadStatus(true);

          if (isFileAvailable[counter] !== undefined) {
            isFileAvailable[counter] = filePath;
            isTargetAvilable[counter] = file;
          } else {
            handleFileChange(filePath);
            setInputTargetFiles(file);
          }
          return fileName;
        } else {
          setError(
            "The word 'sparks:' was not found within 100 words context."
          );
          setDisplayMsg(true);

          fileUploadStatus(false);
        }
      } catch (error) {
        setError("Error parsing PDF or extracting text.");
      }
    };

    reader.readAsArrayBuffer(file);
  };
  // }
  // eslint-disable-next-line eqeqeq

  useEffect(function () {
    setFileTitle("PDF");
    fileUploadStatus(false);
    if (isFileAvailable[counter] !== undefined) {
      setMsg(isFileAvailable[counter].split("\\").pop() + " File uploaded");
      setDisplayMsg(true);
      fileUploadStatus(true);
    }
  }, [counter, fileUploadStatus, isFileAvailable, setFileTitle]);
  return (
    <div className="fileLabel">
      <div className="fileLabel">
        <input
          type="file"
          id="uploadPDF"
          accept=".pdf"
          className="uploadInput"
          onChange={handleFileUpload}
        />
        <label htmlFor="uploadPDF" className="icon pdf-icon"></label>
        {displayMsg && (
          <p className={`statusMsg ${error ? "errorMsg" : "succesMsg"}`}>{`${
            error ? error : msg
          }`}</p>
        )}
      </div>
    </div>
  );
};

export default UploadPdf;
